<template>
  <div>
    <div class="flex-row flex items-center lg:hidden s-mobile-navbar">
      <div
        class="s-navbar-menu h-full flex flex-row overflow-y-auto overflow-x-visible"
        :class="{ opened: xIsNavbarOpen }"
      >
        <div
          class="s-menu-header s-top-bar-address-dropdown text-xs !border-0 w-full flex flex-col"
          :class="{ 'h-full overflow-auto': placesMenuOpen }"
          aria-haspopup="true"
        >
          <div class="flex justify-between gap-2 py-4 px-5 w-full bg-white border-b-2 border-[#E5E7EB]">
            <div class="flex gap-x-4 sm:gap-x-6 items-center">
              <button @click="closeMobileNavbar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="24"
                  viewBox="0 0 22 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2287 10.4717C11.0121 10.5077 10.8003 10.6225 10.6137 10.816L2.66573 19.0583C2.46305 19.2685 2.34918 19.5536 2.34918 19.8508C2.34918 20.1481 2.46305 20.4332 2.66573 20.6434C2.86842 20.8536 3.14332 20.9716 3.42996 20.9716C3.7166 20.9716 3.9915 20.8536 4.19418 20.6434L12.1159 12.4283C12.3325 12.3923 12.5443 12.2775 12.7309 12.084L20.6789 3.8417C20.8816 3.63151 20.9954 3.34643 20.9954 3.04917C20.9954 2.75191 20.8816 2.46683 20.6789 2.25664C20.4762 2.04645 20.2013 1.92836 19.9146 1.92836C19.628 1.92836 19.3531 2.04645 19.1504 2.25664L11.2287 10.4717ZM20.9165 20.2807C20.8613 20.4167 20.7805 20.5401 20.6789 20.6434C20.2203 21.1189 19.609 21.1189 19.1504 20.6434L12.4252 13.6691C13.1895 12.7181 14.5651 12.7181 15.4821 13.6691L20.6789 19.0583C20.7805 19.1616 20.8613 19.2849 20.9165 19.421C20.9716 19.5571 21 19.7032 21 19.8508C21 19.9984 20.9716 20.1446 20.9165 20.2807ZM2.42814 2.61935C2.48329 2.48326 2.56406 2.35996 2.66573 2.25665C3.12427 1.78113 3.73565 1.78113 4.19418 2.25665L10.9194 9.23092C10.1551 10.182 8.77954 10.182 7.86247 9.23092L2.66573 3.84171C2.56406 3.7384 2.48329 3.61509 2.42814 3.47901C2.373 3.34292 2.3446 3.19679 2.3446 3.04918C2.3446 2.90157 2.373 2.75544 2.42814 2.61935Z"
                    fill="#002D52"
                  />
                </svg>
              </button>
              <img
                src="@/assets/icon/png/logo.png"
                class="w-[140px] h-[21px]"
              />
            </div>
            <NuxtLink
              v-if="xIsAuthenticated"
              to="/profile/loyalty"
              class="flex justify-center font-medium font-alt min-[390px]:text-sm text-xs items-center bg-teal min-[390px]:px-4 px-1 py-2 text-white uppercase rounded-full text-center"
            >
              Get Free Weed
            </NuxtLink>
          </div>
          <PageHeaderPickupDeliveryControls
            :context="'mobile-menu'"
            :menuOpenProp="placesMenuOpen"
            @open="openModal"
            @close="$emit('close')"
            @open-places-menu="openPlacesMenu"
            @close-places-menu="closePlacesMenu"
          />
          <div
            class="bg-white min-h-[106px] w-full flex justify-center items-center border-t !mt-auto border-[#838383] fixed bottom-0"
            v-if="placesMenuOpen"
          >
            <button
              class="flex items-center justify-center h-full gap-5"
              @click="closePlacesMenu"
            >
              <IconCaretLeft class="w-4 h-4" />
              <span class="font-alt font-medium text-base"> Back </span>
            </button>
          </div>
        </div>

        <template v-if="!placesMenuOpen">
          <MegaMenuMobile @hyperlink="gotToHyperlink" />
        </template>
      </div>

      <div
        class="s-navbar-overlay"
        :class="{ opened: xIsNavbarOpen }"
        @click="CloseMobileNavbar"
      ></div>

      <div
        class="s-mobile-navbar-container flex flex-row items-center"
        :class="{ opened: xIsNavbarOpen }"
      >
        <div
          class="s-mobile-navbar-hamburger-menu flex items-center justify-center"
          @click="xToggleMobileNavbar"
        >
          <IconHamburgerMenu class="s-hamburger-svg" />
        </div>

        <div class="s-mobile-navbar-items flex flex-row items-center justify-between">
          <NuxtLink
            to="/"
            class="flex items-center ml-2 lg:ml-6 s-mobile-navbar-logo"
          >
            <img
              src="/images/brand/mobile-logo.svg?url"
              alt="The Flowery"
            />
          </NuxtLink>
          <nav class="flex items-center text-base justify-center ml-auto h-full space-x-2 px-[5px]">
            <a
              id="conversation-badge"
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              @click="openChatBubble"
              @touchstart="openChatBubble"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/chat-bubble.svg?url"
                alt=""
              />
              <span
                id="unread-indicator"
                class="s-badge s-badge-secondary"
                >0</span
              >
            </a>
            <NuxtLink
              v-if="xIsAuthenticated"
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              :to="'/profile/dashboard'"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/account.svg?url"
                alt=""
              />
            </NuxtLink>
            <a
              v-else
              class="text-white s-top-bar-link-svg s-top-bar-account cursor-pointer text-right"
              @click="xOpenLoginPopup"
            >
              <img
                class="s-top-bar-svg"
                src="@/assets/icon/svg/account.svg?url"
                alt=""
              />
            </a>
            <RenderlessXCart v-slot="{ xShoppingCart }">
              <NuxtLink
                :to="'/cart'"
                class="text-white s-top-bar-link-svg"
              >
                <img
                  class="s-top-bar-svg"
                  src="@/assets/icon/svg/cart.svg?url"
                  alt=""
                />
                <span class="s-badge s-badge-secondary">{{ xShoppingCart.totalQuantity }}</span>
              </NuxtLink>
            </RenderlessXCart>
            <ShopPageLink class="s-highlighted items-center px-8 h-full ml-6 hidden lg:inline-flex">
              Order now
            </ShopPageLink>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import _phone from '@/utils/filters/phone'
  import _shortName from '@/utils/filters/short-name'
  import MegaMenuMobile from '@/components/mega-menu/MegaMenuMobile'

  import { $api } from '@/utils/api'

  export default {
    name: 'MobileNavbar',
    emits: ['close', 'open', 'hyperlink'],
    data: () => ({
      count: 0,
      placesMenuOpen: false
    }),
    components: { MegaMenuMobile },
    computed: {
      ...mapGetters('authuser', ['xCurrentUser', 'xIsAuthenticated']),
      ...mapGetters(['GET_HEADER_DATA', 'xIsNavbarOpen']),

      phone() {
        return _phone(this?.xCurrentUser?.phone || null)
      },

      shortname() {
        return _shortName(this?.xCurrentUser)
      },

      addressList() {
        return this?.xCurrentUser?.addresses || null
      }
    },
    async mounted() {
      this.getCartCount()
    },
    methods: {
      ...mapActions('auth', ['xLogout', 'xOpenLoginPopup']),
      ...mapActions(['xToggleMobileNavbar', 'xCloseMobileNavbar']),
      ...mapActions('cart', ['xGetCartCount']),

      async getCartCount() {
        this.count = await this.xGetCartCount()
      },
      async logoutCurrentUser() {
        await this.xLogout()
        const url = window.location.href
        window.location.href = url
      },
      openChatBubble() {
        const unreadIndicator = document.querySelector('#unread-indicator')

        const populateUnreadIndicator = (count) => {
          if (!count) return resetUnreadIndicator()

          unreadIndicator.innerHTML = count
        }

        const resetUnreadIndicator = () => {
          unreadIndicator.innerHTML = 0
        }

        zE('messenger', 'open')

        zE('messenger:on', 'unreadMessages', (count) => {
          console.log(count)
          populateUnreadIndicator(count)
        })
      },
      openPlacesMenu(e) {
        this.placesMenuOpen = true
        this.$emit('open', e)
      },
      closePlacesMenu() {
        this.placesMenuOpen = false
        this.$emit('close')
      },
      openModal(modal) {
        this.$emit('open', modal)
      },
      closeMobileNavbar() {
        this.$store.dispatch('xToggleMobileNavbar')
        this.closePlacesMenu()
      },

      gotToHyperlink(hyperlink) {
        this.$emit('hyperlink', hyperlink)
      }
    }
  }
</script>

<style lang="postcss">
  @media screen and (max-width: 1024px) {
    .s-navbar-menu {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      overflow: auto;
      background: #f5f5f5;
      width: 100vw;
      transition: all 0.5s ease-in-out 0s;
      transform: translate3d(-100%, 0, 0);
      @apply z-30 fixed w-full;

      &.opened {
        visibility: visible;
        transition: transform 0.5s ease-in-out 0s;
        transform: translate3d(0, 0, 0);
      }

      .s-menu-header {
        border-bottom: 1px solid #eef1f3;
      }

      .s-menu-logo {
        padding-top: 11px;
        padding-bottom: 15px;
      }

      .s-menu-btn-order {
        background: #3fb9c5;
        font-family: DM Mono, serif;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.375px;
        color: #fff;
        padding: 19px 0 17px;
      }

      .s-menu-personal-info {
        flex-direction: column;
        @apply p-2 py-0 pb-0;

        .allotment {
          .allotment-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:first-child {
              border-right: 1px solid #eef1f3;
            }

            .item {
              font-weight: 500;
              color: #4899b9;
            }

            .header {
              font-weight: 500;
              text-transform: uppercase;
              color: #7c93a5;
            }
          }
        }

        .s-menu-btn-my-acc {
          @apply p-4 items-center self-center;

          .s-menu-btn-my-acc-user-svg {
            width: 45px;
            height: 45px;
            @apply self-center mr-3;
          }

          .s-menu-btn-my-acc-content {
            font-weight: 500;
            font-family: DM Mono, serif;
            background: #fff;
            border: 1px solid #f3b6d0;
            box-shadow: 0 0 7px #f3b6d0;

            .header {
              text-transform: uppercase;
              color: #7c93a5;
            }

            .user {
              color: #3fb9c5;
              @apply text-base;
            }
          }

          .s-menu-btn-my-acc-arrow-right {
            width: 10px;
            height: 14px;
            margin-left: 20px;
            fill: #002d52;
          }
        }
      }

      .s-menu-weed,
      .s-menu-best-buds,
      .s-menu-resources {
        .s-menu-weed-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eef1f3;
          align-items: center;

          .header-weed {
            color: #3fb9c5;
          }

          .s-arrow {
            margin-right: 18px;
          }
        }

        .s-menu-weed-content {
          display: flex;
          flex-wrap: wrap;
          @apply p-3;

          .weed-item {
            font-weight: 500;
            color: #000;
            @apply font-alt;
            @apply p-1;
          }

          .weed-item-710 {
            width: 77px;
            height: 11px;
          }

          .weed-item-flowery {
            width: 75px;
          }

          .weed-item-packwoods {
            width: 82px;
          }
        }
      }
    }

    .s-menu-resources-content {
      @apply font-alt;
      @apply px-6 text-sm font-semibold mb-10 leading-6;

      .address {
        color: #000;
      }

      .social-media-container {
        margin-top: 14px;
        display: flex;
        justify-content: space-around;
        width: 100%;
      }
    }

    .s-dropdown-resource-link {
      @apply font-alt;
    }

    .arrow-top {
      transform: rotate(-90deg);
    }

    .arrow-down {
      transform: rotate(90deg);
    }

    .s-dropdown-resource-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      color: #f3b6d0;
    }

    .s-top-bar-address-dropdown {
      @apply text-center no-underline px-0;

      > span {
        @apply text-teal no-underline;
      }

      > svg {
        @apply text-teal no-underline;
      }

      .s-dropdown-menu {
        position: absolute;
        text-align: left;
        height: auto;
        z-index: 10;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
        top: 49px !important;

        width: 325px;
        @apply left-0 px-0;
        @media screen and (max-width: 800px) {
          width: 100%;
        }

        .s-dropdown-menu-item {
          label {
            @apply text-xs;
          }

          .s-dropdown-menu-default {
            height: 25px;
            transform: translateY(50%);
          }
        }
      }

      &:hover,
      &:active {
        .s-top-bar-chevron {
          transform: scaleY(-1);
        }

        .s-dropdown-menu {
          display: block;
        }
      }
    }

    .s-dropdown-menu-item-checkbox:checked + *::before {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.13813L6.84888 15.0001L20 1.86195L18.1119 0L6.84888 11.25L1.86191 6.26306L0 8.13813Z' fill='%233FB9C5'/%3E%3C/svg%3E%0A");
    }

    .s-dropdown-canabis-icon {
      width: 34px;
      height: 40px;
    }

    .s-navbar-overlay {
      visibility: hidden;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: #002d52;
      mix-blend-mode: normal;
      transition: all 0.5s ease 0s;
      opacity: 0;
      @apply z-10 fixed;

      &.opened {
        transition: opacity 0.5s ease 0s;
        opacity: 0.75;
        visibility: visible;
      }
    }

    .s-mobile-navbar {
      height: 60px;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      @apply bg-white;
    }

    .s-mobile-navbar-container {
      width: 100vw;
      height: 100%;
      overflow: hidden;
      transition: all 0.5s ease 0s;
      transform: translate3d(0, 0, 0);
      @apply relative;

      &.opened {
        transition: transform 0.5s ease 0s;
      }
    }

    .s-mobile-navbar-hamburger-menu {
      width: 67px;
      height: 100%;
      cursor: pointer;

      .s-hamburger-arrow {
        color: #000;
        margin-right: 7px;
      }
    }

    .s-mobile-navbar-items {
      background: #000;
      @apply h-full w-screen;
    }

    .s-mobile-navbar-logo {
      width: 200px;

      @media screen and (max-width: 400px) {
        width: 152px;
      }
    }

    .s-top-bar-link-svg {
      position: relative;
      display: inline-block;
    }

    .s-badge {
      position: absolute;
      top: 0;
      right: 0;
    }

    .s-badge-secondary {
      background: #f3b6d0ff;
      color: #002d52ff;
      @apply font-alt;
      @apply text-xs rounded-full px-1;
    }

    .s-highlighted {
      background: #3fb9c5ff;
      @apply font-alt;
      text-transform: capitalize;
      color: #fff;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        max-width: 129px;
      }

      @media screen and (max-width: 553px) {
        display: none;
      }
    }

    .s-highlighted:hover {
      cursor: pointer;
      background: #f3b6d0ff;
      color: #002d52ff;
    }

    .s-margin-left-auto {
      margin-left: auto;
    }

    .s-top-bar-svg {
      @media screen and (max-width: 767px) {
      }
    }

    .s-top-bar-account {
    }

    .s-top-bar-basket {
    }

    .s-mobile-navbar-menu-btn {
      background: #fff;
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
    }

    .s-mobile-navbar-menu-btn img {
      margin-right: 4px;
      margin-left: 4px;
    }
    .jobitem {
      padding: 0 !important;
    }

    .mobile-header {
      @apply text-[0px] mt-[5px] mb-[0];
    }
  }
</style>
