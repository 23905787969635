<template>
  <div class="lg:mt-4 w-full flex flex-col h-full">
    <div>
      <h5 class="font-alt text-sm text-[#404040] hidden lg:block">
        Discover your perfect strain and explore by type to find your vibe
      </h5>
      <div class="lg:hidden flex justify-start gap-x-2 items-center border-b-[1px] border-[#D9D9D9] py-5 px-6">
        <button
          class="outline-none border-none"
          @click="emit('close')"
        >
          <IconCaretLeft class="w-4 h-4 mr-4" />
        </button>
        <h5
          @click="emit('close')"
          class="font-main text-[#F3B6D0] uppercase font-bold text-text-start font-alt text-xl"
        >
          Strain
        </h5>
      </div>
      <div class="mt-4 grid grid-cols-1 xl:grid-cols-3 gap-4 px-[24px] lg:px-0">
        <template
          v-for="detail in STRAIN_DETAILS"
          :key="detail.title"
        >
          <MegaMenuCard
            :text="detail.text"
            :title="detail.title"
            :icon="detail.icon"
            :redirectLink="detail.redirectLink"
            :external="false"
            :link="detail.link"
            @hyperlink="redirect"
          />
        </template>
      </div>
    </div>
    <MegaMenuMobileBackFooter @click="emit('close')" />
  </div>
</template>

<script setup>
  import { STRAIN_DETAILS } from '@/components/mega-menu/constants'
  import MegaMenuCard from '@/components/mega-menu/MegaMenuCard'

  const emit = defineEmits(['close', 'hyperlink'])
  const redirect = (link) => {
    console.log('Redirecting to:', link)
    emit('hyperlink', link)
  }
</script>
