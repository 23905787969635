<template>
  <div class="flex xl:w-[1090px] lg:w-[850px] w-[800px] rounded-sm bg-[#f5f5f5] p-2">
    <div class="w-[31%] py-6 pl-6">
      <p class="text-lg font-medium text-start font-alt w-4/5">How would you like to shop by?</p>
      <div class="flex flex-col gap-y-4 mt-4">
        <template
          v-for="item in BUTTON_DETAILS"
          :key="item.name"
        >
          <MegaMenuBtn
            :name="item.name"
            @click="setActiveOption(item.id)"
            :id="item.id"
            :active="activeOption === item.id"
          />
        </template>
        <MegaMenuMerchLink />
      </div>
    </div>
    <div class="w-[69%] bg-white rounded-md p-6">
      <div class="w-full h-12 pb-4 flex justify-between items-center border-b-[1px] border-[#D9D9D9]">
        <h5 class="font-main text-[#F3B6D0] uppercase font-bold text-text-start font-alt text-3xl">
          {{ selectedOption.name }}
        </h5>
      </div>
      <MegaMenuBrand
        v-if="activeOption === 'brands'"
        @hyperlink="redirect"
      />
      <MegaMenuCategories
        v-if="activeOption === 'categories'"
        @hyperlink="redirect"
      />
      <MegaMenuStrainType
        v-if="activeOption === 'strain-type'"
        @hyperlink="redirect"
      />
    </div>
  </div>
</template>

<script setup>
  import { BUTTON_DETAILS } from '@/components/mega-menu/constants'
  import MegaMenuBtn from '@/components/mega-menu/MegaMenuBtn'
  import MegaMenuStrainType from '@/components/mega-menu/MegaMenuStrainType'
  import MegaMenuCategories from '@/components/mega-menu/MegaMenuCategories'
  import MegaMenuBrand from '@/components/mega-menu/MegaMenuBrand'

  import { ref } from 'vue'

  const activeOption = ref(BUTTON_DETAILS[0].id)

  const emit = defineEmits(['hyperlink'])

  function redirect(value) {
    emit('hyperlink', value)
  }

  function setActiveOption(buttonId) {
    activeOption.value = buttonId
  }

  const selectedOption = computed(() => {
    return BUTTON_DETAILS.find((item) => item.id === activeOption.value)
  })

  function handleClearActions(clearQuery) {
    // Temporary fix for clearing filters - we need to expand how menu works + use Vue emitters. Out of scope for now.
    document.querySelector('.s-shop-cleat-filter-btn').click()
    if (typeof clearQuery === 'function') {
      clearQuery()
    }
  }
</script>
