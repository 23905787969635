export const BUTTON_DETAILS = [
  {
    name: 'Brands',
    id: 'brands',
    linkText: 'Brands',
    link: 'shop',
    external: false,
  },
  {
    name: 'Categories',
    id: 'categories',
    linkText: 'Items',
    link: 'shop',
    external: false,
  },
  {
    name: 'Strain Type',
    id: 'strain-type',
    linkText: 'Products',
    link: 'shop?lineages%5B%5D=sativa&lineages%5B%5D=hybrid&lineages%5B%5D=indica',
    external: false,
  }
];

export const STRAIN_DETAILS = [
  {
    title: 'Sativa',
    icon: 'sativa',
    link: 'shop?lineages%5B%5D=sativa&lineages%5B%5D=hybrid%2Fsativa',
    redirectLink: 'lineages[]=sativa&lineages[]=hybrid/sativa',
    text: 'Known for uplifting and energizing effects, often associated with creativity and daytime use.',
  },
  {
    title: 'Indica',
    icon: 'indica',
    link: 'shop?lineages%5B%5D=hybrid%2Findica&lineages%5B%5D=indica',
    redirectLink: 'lineages[]=indica&lineages[]=hybrid/indica',
    text: 'Typically provides relaxing and calming effects, commonly used for evening or nighttime relaxation.',
  },
  {
    title: 'Hybrid',
    icon: 'hybrid',
    link: 'shop?lineages%5B%5D=hybrid%2Fsativa&lineages%5B%5D=hybrid&lineages%5B%5D=hybrid%2Findica',
    redirectLink: 'lineages[]=hybrid&lineages[]=hybrid/sativa&lineages[]=hybrid/indica',
    text: "A combination of Sativa and Indica, offering a balance of both effects, depending on the strain's genetic makeup.",
  },
]

export const BRANDS_TO_REPLACE = {
  'Back Pack Boyz': 'shop?brands[]=Backpack+Boyz',
  'Packs': 'shop?brands[]=PACKS'
}

export const BRAND_LINKS = {
  'brands%5B%5D=Runtz': 'brands[]=Runtz',
  'brands%5B%5D=Packs': 'brands[]=PACKS',
  'brands%5B%5D=Back Pack%20Boyz': 'brands[]=Backpack Boyz',
  'brands[]=UD+x+The+Flowery': 'brands[]=UD x The Flowery',
}

export const CATEGORIES_TO_REPLACE = {
  'Medical Devices': 'Medical Device'
}
