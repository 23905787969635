<template>
  <div class="flex flex-col justify-between z-15">
    <div class="px-4 flex flex-col gap-y-4 pt-[8px] pb-[48px]">
      <p class="font-alt text-lg font-medium text-start mt-[23px]">How would you like to shop by?</p>
      <button
        v-for="(item, index) in BUTTON_DETAILS"
        :key="'button-' + item.id"
        class="h-21 w-full flex items-center justify-between text-lg font-alt font-medium bg-white py-6 px-8"
        @click="setActiveOption(item.id)"
      >
        {{ item.name }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="14px"
          viewBox="0 0 8 14"
          fill="none"
          :data-v="'clip-' + index"
        >
          <g :clip-path="'url(#clip-' + index + ')'">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.31411 6.68806C6.97125 6.34531 6.51411 6.34531 6.17125 6.68806L0.228398 12.6291C0.0768456 12.7806 -0.00829562 12.986 -0.00829561 13.2003C-0.0082956 13.4146 0.0768456 13.6201 0.228398 13.7716C0.37995 13.9231 0.585499 14.0082 0.799826 14.0082C1.01415 14.0082 1.2197 13.9231 1.37125 13.7716L7.31411 7.83056C7.65697 7.48781 7.65697 7.03081 7.31411 6.68806ZM0.228397 0.518558C0.152375 0.593021 0.0919795 0.681902 0.0507487 0.779992C0.00951798 0.878082 -0.0117193 0.98341 -0.0117193 1.08981C-0.0117193 1.19621 0.009518 1.30154 0.0507488 1.39963C0.0919795 1.49771 0.152375 1.5866 0.228397 1.66106L4.11411 5.54556C4.79983 6.23106 5.8284 6.23106 6.39983 5.54556L1.37125 0.518558C1.0284 0.175809 0.571254 0.175809 0.228397 0.518558Z"
              fill="#002D52"
            />
          </g>
          <defs>
            <clipPath :id="'clip-' + index">
              <rect
                width="13.71"
                height="8"
                fill="white"
                transform="translate(0 14) rotate(-90)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <MegaMenuMerchMobileLink />
    </div>
    <div
      v-if="!activeOption"
      class="flex flex-col custom-buttons"
    >
      <MegaMenuMobileButtons />
      <MegaMenuMobileInviteNew />
    </div>
  </div>
  <div
    class="s-menu-option bottom-0"
    :class="{
      active: activeOption === 'brands',
      'h-full flex': activeOption === 'brands',
      '': isAppleDevice
    }"
  >
    <MegaMenuBrand
      @close="setActiveOption(null)"
      @hyperlink="redirect"
    />
  </div>
  <div
    class="s-menu-option bottom-0 flex h-full"
    :class="{
      active: activeOption === 'categories'
    }"
  >
    <MegaMenuCategories
      @close="setActiveOption(null)"
      @hyperlink="redirect"
    />
  </div>
  <div
    class="s-menu-option bottom-0 flex"
    :class="{
      active: activeOption === 'strain-type',
      'h-full': activeOption === 'strain-type',
      '': isAppleDevice
    }"
  >
    <MegaMenuStrainType
      @close="setActiveOption(null)"
      @hyperlink="redirect"
      @back="setActiveOption(null)"
    />
  </div>

  <div
    class="s-menu-option bottom-0"
    :class="{ active: activeOption === 'merch' }"
  ></div>
</template>

<script setup>
  import { BUTTON_DETAILS } from '@/components/mega-menu/constants'
  import MegaMenuStrainType from '@/components/mega-menu/MegaMenuStrainType'
  import MegaMenuCategories from '@/components/mega-menu/MegaMenuCategories'
  import MegaMenuBrand from '@/components/mega-menu/MegaMenuBrand'
  import MegaMenuMerchMobileLink from '@/components/mega-menu/MegaMenuMerchMobileLink'

  import { ref, computed, onMounted } from 'vue'

  const isAppleDevice = ref(false)

  const detectAppleDevice = () => {
    const userAgent = navigator?.userAgent || navigator?.vendor || window.opera
    isAppleDevice.value = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
  }

  onMounted(() => {
    detectAppleDevice()
  })

  const emit = defineEmits(['hyperlink'])

  const redirect = (value) => {
    emit('hyperlink', value)
  }

  const activeOption = ref(null)

  const setActiveOption = (option) => {
    activeOption.value = option
  }
</script>

<style scoped lang="postcss">
  .s-menu-option {
    position: fixed;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    oveflow: auto;
    top: 0;
    left: 0;
    background: white;
    transition: all 0.5s ease-in-out 0s;
    transform: translate3d(-100%, 0, 0);
    z-index: 50;
    width: 100%;

    &.active {
      visibility: visible;
      transition: transform 0.5s ease-in-out 0s;
      transform: translate3d(0, 0, 0);
    }
  }
  .back-fix {
    height: calc(74vh - env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
  }
</style>
