<template>
  <button
    class="bg-transparent hover:bg-white flex justify-start gap-x-3 items-center rounded-l-full h-[77px] pl-6 relative"
    :class="{
      'bg-white': active
    }"
    @click="handleClick"
  >
    <span class="font-bold text-base text-black-900">
      {{ name }}
      <div
        v-if="active"
        class="absolute right-0 top-[-20px] bg-white w-5 h-5 up"
      ></div>
      <div
        v-if="active"
        class="absolute right-0 bottom-[-20px] bg-white w-5 h-5 down"
      ></div>
    </span>
  </button>
</template>

<script setup>
  import BrandIcon from '@/assets/icon/svg/mega-menu/brands.svg'
  import CategoriesIcon from '@/assets/icon/svg/mega-menu/categories.svg'
  import MerchIcon from '@/assets/icon/svg/mega-menu/merch.svg'
  import StrainTypeIcon from '@/assets/icon/svg/mega-menu/strain-type.svg'

  const props = defineProps({
    name: String,
    id: String,
    active: Boolean
  })

  const emit = defineEmits(['click'])

  const handleClick = () => {
    emit('click')
  }
</script>

<style scoped lang="postcss">
  .up {
    clip-path: polygon(88% 29%, 100% 0, 100% 35%, 100% 100%, 79% 100%, 50% 100%, 0 100%, 23% 93%, 48% 78%, 71% 56%);
  }

  .down {
    clip-path: polygon(100% 77%, 100% 0, 55% 0, 0 0, 34% 17%, 57% 36%, 75% 53%, 85% 68%, 94% 85%, 100% 100%);
  }
</style>
