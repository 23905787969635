<template>
  <div class="flex justify-around mb-[28px]">
    <a
      href="https://support.theflowery.co/hc/en-us"
      class="flex flex-col items-center justify-center gap-y-2"
    >
      <button>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21L14.4457 16.3043H18.75C19.9926 16.3043 21 15.297 21 14.0543V5.25C21 4.00736 19.9926 3 18.75 3H5.25C4.00736 3 3 4.00736 3 5.25V14.0543C3 15.297 4.00736 16.3043 5.25 16.3043H9.75L12 21Z"
            fill="black"
          />
          <path
            d="M12 9.375V9.45959"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 9.375V9.45959"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 9.375V9.45959"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <span class="text-sm text-black-900 font-alt capitalize">support</span>
    </a>
    <div class="flex flex-col items-center justify-center gap-y-2">
      <button @click="openChatBubble">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.1406 15.3677C18.5439 14.4554 18.7678 13.4462 18.7678 12.3846C18.7678 8.30621 15.4619 5 11.3839 5C7.30589 5 4 8.30621 4 12.3846C4 16.463 7.30589 19.7692 11.3839 19.7692C12.6969 19.7692 13.9298 19.4265 14.9981 18.8257L20 21L18.1406 15.3677Z"
            fill="black"
          />
          <circle
            cx="17.5"
            cy="6.5"
            r="5.5"
            fill="#38C9CF"
          />
        </svg>
      </button>
      <span class="text-sm text-black-900 font-alt capitalize">chat</span>
    </div>
    <div class="flex flex-col items-center justify-center gap-y-2">
      <a href="tel:+13059122929">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4.49371 7.95952C5.93339 13.6147 10.3853 18.0666 16.0405 19.5063C18.1813 20.0513 20 18.2091 20 16V15C20 14.4477 19.5512 14.0053 19.0016 13.9508C18.073 13.8587 17.1818 13.6397 16.3456 13.3112L14.8263 14.8305C12.3553 13.648 10.352 11.6447 9.16949 9.17367L10.6888 7.65438C10.3603 6.81816 10.1413 5.92696 10.0492 4.99842C9.99474 4.44883 9.55229 4 9 4H8C5.79086 4 3.94869 5.81867 4.49371 7.95952Z"
            fill="black"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <span class="text-sm text-black-900 font-alt capitalize">call</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MegaMenuMobileButtons',
    emits: ['close', 'open', 'hyperlink'],
    methods: {
      openChatBubble() {
        const unreadIndicator = document.querySelector('#unread-indicator')

        const populateUnreadIndicator = (count) => {
          if (!count) return resetUnreadIndicator()

          unreadIndicator.innerHTML = count
        }

        const resetUnreadIndicator = () => {
          unreadIndicator.innerHTML = 0
        }

        zE('messenger', 'open')

        zE('messenger:on', 'unreadMessages', (count) => {
          populateUnreadIndicator(count)
        })
      }
    }
  }
</script>
