<template>
  <div>
    <div class="w-full">
      <div class="navbar flex flex-nowrap h-20 flex-col md:flex-row items-center hidden lg:flex s-container">
        <NuxtLink
          class="flex items-center ml-6"
          :to="'/'"
        >
          <NuxtImg
            :src="'/images/brand/logo.png'"
            width="350"
            alt="The Flowery Logo"
            :modifiers="{ q: 75 }"
          />
        </NuxtLink>
        <nav class="md:ml-auto flex flex-nowrap items-center text-base justify-center h-full">
          <div class="s-dropdown relative flex h-full items-center mr-3 xl:mr-7 px-6 cursor-pointer">
            <a class="s-submenu s-navbar-link s-menu-item-has-children">
              Menu
              <IconChevron class="s-navbar-default-chevron inline-block h-2 ml-2" />
            </a>
            <div class="s-dropdown-menu rounded-b-[8px] group top-20">
              <MegaMenu @hyperlink="gotHyperlink" />
            </div>
          </div>
          <NuxtLink
            :to="'/loyalty-program'"
            class="s-navbar-link inline-flex h-full items-center cursor-pointer mr-3 xl:mr-7 px-6"
            >Loyalty
          </NuxtLink>
          <div class="s-dropdown-resource relative flex h-full items-center cursor-pointer mr-3 xl:mr-7 px-6">
            <a class="s-navbar-link">
              Resources
              <IconChevron class="s-navbar-default-chevron inline-block h-2 ml-2" />
            </a>
            <div class="s-dropdown-menu-resource hidden h-auto group w-full top-20">
              <div class="flex pb-4">
                <div class="s-dropdown-resource-title w-9/12">Resources</div>
                <div class="s-dropdown-resource-title w-3/12">Find a location</div>
              </div>

              <div class="flex">
                <div class="flex w-9/12">
                  <div>
                    <NuxtLink
                      class="s-dropdown-resource-link block"
                      :to="'/about'"
                    >
                      About The Flowery
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/doctors'"
                    >
                      How-To Get a Medical Card
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/resources'"
                    >
                      What are Terpenes?
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/doctors'"
                    >
                      Find a Doctor
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="`/delivery-regions-coverage`"
                    >
                      Delivery Regions Coverage
                    </NuxtLink>
                  </div>
                  <div class="pl-24">
                    <NuxtLink
                      class="s-dropdown-resource-link block"
                      :to="'/blog'"
                    >
                      Blog
                    </NuxtLink>
                    <NuxtLink
                      class="s-dropdown-resource-link block mt-3"
                      :to="'/media'"
                    >
                      Media + Events
                    </NuxtLink>
                    <a
                      class="s-dropdown-resource-link block mt-3"
                      target="_blank"
                      rel="noopener"
                      href="https://support.theflowery.co/hc/en-us"
                    >
                      FAQ
                    </a>
                  </div>
                </div>
                <div class="w-3/12">
                  <NuxtLink
                    to="/locations"
                    class="flex justify-end"
                  >
                    <NuxtImg
                      :src="'/images/dropdown-location.png'"
                      class="-mt-2"
                      alt="Find a Flowery location"
                      width="219"
                      height="172"
                      :modifiers="{ q: 75 }"
                      loading="lazy"
                    />
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <ShopPageLink class="s-highlighted inline-flex items-center px-7 xl:px-14 mt-4 md:mt-0 h-full">
          Order now
        </ShopPageLink>
      </div>
    </div>
    <NotificationMessages />
  </div>
</template>

<script setup>
  const props = defineProps({
    topNavBar: {
      type: Function,
      required: true
    }
  })

  const yPos = ref('56px')

  onMounted(() => {
    useIntersectionObserver(
      props.topNavBar().$el,
      ([{ isIntersecting, intersectionRect }], _) => {
        if (isIntersecting) {
          yPos.value = `${intersectionRect.height}px`
        } else {
          yPos.value = '0px'
        }
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    )
  })
</script>

<script>
  import { mapGetters } from 'vuex'
  import MegaMenu from '@/components/mega-menu/MegaMenu.vue'

  export default {
    emits: ['hyperlink'],
    components: {
      MegaMenu
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA']),

      /**
       * The featured product that appears in the navbar. If no featured product exists this will return null
       * @returns {null|{image: string, url: string}}
       */
      featuredProduct() {
        let url = this?.GET_HEADER_DATA?.weed_featured_product?.weed_featured_product_link?.post_name || null
        if (url) {
          url = '/product/' + url
        }

        const image = this?.GET_HEADER_DATA?.weed_featured_product?.weed_featured_product_image?.url || null

        // If either the image url or the hyperlink is null then we dont have a featured product
        if (url && image) {
          return {
            url,
            image
          }
        }

        return null
      }
    },

    methods: {
      /**
       * Called when salve-link component emits a hyperlink event
       * @param link
       */
      gotHyperlink(link) {
        console.log('Received hyperlink:', link)
        this.$emit('hyperlink', link)
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .navbar {
    position: fixed;
    width: 100vw;
    background: white;
    z-index: 101;
    transition: all 0.35s ease;
    top: v-bind(yPos);
  }

  .s-container {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.106568);
  }

  .s-navbar-link {
    @apply font-main;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    color: #002d52ff;
  }

  .s-highlighted {
    background: #f3b6d0;
    @apply font-alt;
    text-transform: capitalize;
    color: black;
  }

  .s-highlighted:hover {
    cursor: pointer;
    background: #3fb9c5;
    color: white;
  }

  .s-dropdown-menu {
    position: absolute;
    text-align: left;
    z-index: 10;
    transform: translateX(-56.6%);
    max-height: 75vh;
    overflow-y: auto;
    background: #fff;
    display: block;
    top: -1000px;
  }

  .s-dropdown {
    &:hover {
      .s-navbar-default-chevron {
        transform: scaleY(-1);
      }
      .s-dropdown-menu {
        top: 80px;
      }
    }
  }

  .s-dropdown-menu-resource {
    right: -418px;
    position: absolute;
    text-align: left;
    width: 835px;
    height: 277px;
    z-index: 10;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 7px 8px 0 rgb(0 0 0 / 20%);
    padding: 40px;
  }

  .s-dropdown-resource {
    &:hover {
      .s-navbar-default-chevron {
        transform: scaleY(-1);
      }
      .s-dropdown-menu-resource {
        display: block;
      }
    }
  }

  .s-dropdown-submenu > a {
    @apply font-main;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: #f3b6d0ff;
  }

  span.s-icon img {
    margin: 0.25rem;
    width: 35px;
    height: inherit;
    display: inline-block;
    text-align: left;
  }

  span.s-image img {
    width: 110px;
    height: inherit;
  }

  span.s-special img {
    height: inherit;
    width: inherit;
    max-width: 285px;
  }

  .s-dropdown-menu-top {
    background: #f3b6d0;
    height: 49px;
    position: absolute;
    width: 100%;
  }

  .s-dropdown-menu-top-left {
    height: 49px;
    @apply font-alt;
    font-size: 18px;
    color: #002d52;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid #fcbfd9;
  }

  .s-navbar-marque-text {
    @apply font-alt;
    font-size: 16px;
    font-weight: bold;
    color: #002d52;
    white-space: nowrap;
  }

  .s-navbar-marque-btn {
    @apply font-main;
    font-weight: 700;
    font-size: 9px;
    color: #3fb9c5;
    border-radius: 14px;
    text-transform: uppercase;
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
  }

  .s-dropdown-resource-link {
    color: #002d52;
    @apply font-alt;
    font-size: 18px;
    font-weight: bold;
  }

  .s-dropdown-resource-title {
    @apply font-main;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 18px;
    color: #f3b6d0;
  }
  @media (max-width: 1279px) {
    .s-dropdown-menu {
      transform: translate(-50%);
    }
  }
</style>
