<template>
  <div class="lg:px-0 h-full lg:h-[550px] lg:h-auto overflow-auto flex flex-col">
    <div class="lg:hidden flex justify-start gap-x-[24px] items-center border-b-2 border-[#D9D9D9] py-5 px-6">
      <button
        class="outline-none border-none"
        @click="emit('close')"
      >
        <IconCaretLeft class="w-4 h-4" />
      </button>
      <h5
        @click="emit('close')"
        class="font-main text-[#F3B6D0] uppercase font-bold text-text-start font-alt text-2xl"
      >
        Categories
      </h5>
    </div>
    <div
      class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-x-[50px] gap-x-[40px] lg:px-0 px-[24px] dynamic-height"
    >
      <div
        v-for="(category, categoryIndex) in categoryLinks"
        :key="`category-${categoryIndex}-${category.label}`"
      >
        <NuxtLink
          class="block font-medium uppercase lg:text-xs text-base font-alt text-[#3FB9C5] py-5 lg:pb-[18px] lg:pt-[28px]"
          @click="handleCategoryClick(category)"
          :to="category.labelLink"
          rel="noopener noreferrer"
        >
          {{ category.label }}
        </NuxtLink>
        <div class="flex flex-wrap gap-[8px]">
          <NuxtLink
            v-for="(child, childIndex) in category.children"
            :key="`child-${categoryIndex}-${childIndex}-${child.label}`"
            @click="handleCategoryClick(child)"
            class="flex lg:justify-start justify-center items-center rounded-md bg-[#F5F5F5] lg:bg-white lg:border-2 lg:border-[#F5F5F5] lg:hover:border-[#EA57A8] px-[8px] py-[8px]"
            :to="child.link"
            rel="noopener noreferrer"
          >
            <span class="text-xs font-alt font-medium text-black-900">
              {{ child.label }}
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>
    <MegaMenuMobileBackFooter @click="emit('close')" />
  </div>
</template>
<script setup>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import {
    generateShopCategoryLink,
    generateCategoryLink,
    getCategoryLink,
    getSubcategoryLink,
    getBrand
  } from '@/components/mega-menu/helpers'

  const emit = defineEmits(['close', 'hyperlink'])

  const store = useStore()

  // Fetch required data using Nuxt's asyncData
  const categoryLinks = ref([])

  onMounted(async () => {
    const newControls = store.getters['shop/xShopNewControls']
    const locationId = store.getters['user-region/xSelectedLocationId']
    const headerData = store.getters['GET_HEADER_DATA']
    const weedCategories = headerData.weed_categories ?? []

    categoryLinks.value = Array.isArray(newControls)
      ? newControls.map(({ label, children }) => {
          const weedCategoryLink =
            weedCategories.find((wc) => wc.weed_categoriy_title === label)?.weed_category_link || ''
          return {
            label,
            labelLink: generateCategoryLink(weedCategoryLink, locationId),
            hyperlink: getCategoryLink(locationId, label),
            children: Array.isArray(children)
              ? children.map((child) => ({
                  label: child.label,
                  link: generateShopCategoryLink(locationId, label, child.label),
                  hyperlink: getSubcategoryLink(locationId, label, child.label)
                }))
              : []
          }
        })
      : []
  })

  const handleCategoryClick = (category) => {
    emit('hyperlink', category.hyperlink)
    if (window.innerWidth >= 1024) {
      document.getElementsByClassName('s-dropdown-menu')[0].style.top = '-1000px'
      setTimeout(() => {
        const dropdownMenu = document.getElementsByClassName('s-dropdown-menu')[0]
        if (dropdownMenu) {
          dropdownMenu.style.removeProperty('top')
        }
      }, 100)
    }
  }
</script>
