<template>
  <div class="p-4">
    <template v-if="isAuth === true">
      <div class="flex flex-col justify-center items-center w-full gap-y-2 px-4">
        <div class="flex items-center justify-start flex-col gap-2">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.40039 22.1L2.4008 18.4996C2.40102 16.5116 4.01273 14.9 6.00079 14.9H12.0004M19.028 18.8L21.5994 16.4M21.5994 16.4L19.028 14M21.5994 16.4H14.9994M14.4004 6.50002C14.4004 8.48825 12.7886 10.1 10.8004 10.1C8.81216 10.1 7.20039 8.48825 7.20039 6.50002C7.20039 4.5118 8.81216 2.90002 10.8004 2.90002C12.7886 2.90002 14.4004 4.5118 14.4004 6.50002Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="text-lg font-alt font-medium w-full">Invite friends</p>
        </div>
        <NuxtLink
          to="/profile/loyalty"
          class="flex justify-center font-medium font-alt text-sm items-center bg-teal px-4 py-2 text-white uppercase rounded-full text-center"
        >
          Get Free Weed
        </NuxtLink>
      </div>
    </template>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'

  const store = useStore()
  const isAuth = store.getters['authuser/xIsAuthenticated']

  const openChatBubble = () => {
    const unreadIndicator = document.querySelector('#unread-indicator')

    const populateUnreadIndicator = (count) => {
      if (!count) return resetUnreadIndicator()

      unreadIndicator.innerHTML = count
    }

    const resetUnreadIndicator = () => {
      unreadIndicator.innerHTML = 0
    }

    zE('messenger', 'open')

    zE('messenger:on', 'unreadMessages', (count) => {
      populateUnreadIndicator(count)
    })
  }
</script>
